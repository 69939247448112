import { connect, MapStateToProps } from 'react-redux'
import summarySelector from '../../redux/selectors/summarySelector'
import { fetchSummary } from '../../redux/actions/app/summary.actions'
import { Props } from './OverviewView'
import orderKeySelector from '../../redux/selectors/orderKeySelector'
import configuredCarlineTitleSelector from '../../redux/selectors/carlines/configuredCarlineTitleSelector'
import overviewViewIsLoadingSelector from '../../redux/selectors/is-loading/overviewViewIsLoadingSelector'
import aveStringSelector from '../../redux/selectors/aveStringSelector'
import isShowAveStringEnabledSelector
    from '../../redux/selectors/feature-scope/isShowAveStringEnabledSelector'

type StateProps = Pick<Props, 'summary' | 'carlineTitle' | 'orderKey' | 'isLoading' | 'aveString' | 'showAveString'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    summary: summarySelector(state),
    carlineTitle: configuredCarlineTitleSelector(state),
    orderKey: orderKeySelector(state),
    isLoading: overviewViewIsLoadingSelector(state),
    aveString: aveStringSelector(state),
    showAveString: isShowAveStringEnabledSelector(state),
})

type DispatchProps = Pick<Props, 'loadSummaryData'>

const mapDispatchToProps: DispatchProps = {
    loadSummaryData: fetchSummary,
}

export default connect(mapStateToProps, mapDispatchToProps)
