import React, { FunctionComponent } from 'react'
import AudiExclusiveApp from '../audi-exclusive-app/audiExclusiveAppContainer'
import ConflictModal from '../conflict-modal'
import CornerRibbon from '../corner-ribbon'
import ForesightedBuildabilityModal from '../foresighted-buildability-modal'
import InfoModal from '../info-modal'
import ModelConflictModal from '../model-conflict-modal'
import Navigation from '../navigation'
import RescueVehicleCodeModal from '../rescue-vehicle-code-modal'
import Router from '../router'
import Sidebar from '../sidebar'
import style from './root.css'
import TargetNavigation from '../target-navigation/targetNavigationContainer'
import PrStringInvocationModal from '../invocation-modal'

const PageContent: FunctionComponent = () => (
    <div className={style.pageContainer}>
        <CornerRibbon />
        <Sidebar>
            <Navigation />
        </Sidebar>
        <main className={style.pageContent}>
            <Router />
            <ConflictModal />
            <ForesightedBuildabilityModal />
            <InfoModal />
            <ModelConflictModal />
            <RescueVehicleCodeModal />
            <AudiExclusiveApp />
            <TargetNavigation />
            <PrStringInvocationModal />
        </main>
    </div>
)

export default PageContent
