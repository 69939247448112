import RenderImage from '../../../../entities/RenderImage'

const filterRenderImagesByOrdering = (renderImages: RenderImage[], renderImagesOrdering: string[]) => {
    if (renderImagesOrdering && renderImagesOrdering.length !== 0) {
        return renderImages.map(renderImage => ({
            ...renderImage,
            renderDetails: (() => {
                for (const order of renderImagesOrdering) {
                    const filteredDetails = renderImage.renderDetails.filter(detail => detail.backgroundType?.toLowerCase() === order?.toLowerCase())
                    if (filteredDetails.length > 0) {
                        return filteredDetails
                    }
                }
                return []
            })(),
        }))
    }
    return renderImages
}

export const findViewIndex = (renderImages: RenderImage[], backgroundImageType: string, viewId: string): number => {
    if (!backgroundImageType || !renderImages || renderImages.length === 0 || !viewId) {
        return 0
    }

    const viewIndex = renderImages
        .filter(renderImage =>
            renderImage.renderDetails.some(detail => detail.backgroundType === backgroundImageType))
        .findIndex((image) => image.id === viewId)

    if (viewIndex === -1) {
        const defaultViewIndex = renderImages.findIndex(renderImage => renderImage.id === viewId)
        return defaultViewIndex !== -1 ? defaultViewIndex : 0
    } else {
        return viewIndex
    }

}

export default filterRenderImagesByOrdering
