import React, { FunctionComponent } from 'react'
import { BuildabilityType } from '../../entities/BuildabilityType'
import TextSelectDropdown from '../text-select-dropdown/TextSelectDropdown'
import { OptionType } from '../../entities/OptionType'

export type Props = {
    changeBuildabilityType: Function
    availableBuildabilityTypes: BuildabilityType[]
    currentBuildabilityType: BuildabilityType
}

const BuildabilitySwitcherButton: FunctionComponent<Props> = (props) => {
    const {
        changeBuildabilityType,
        availableBuildabilityTypes,
        currentBuildabilityType,
    } = props

    const onChangeHandler = (selectedOption: OptionType) => {
        const newBuildabilityType = selectedOption.value as BuildabilityType
        changeBuildabilityType(newBuildabilityType)
    }

    const options = availableBuildabilityTypes.map((type) => ({
        value: type,
        label: type.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase()),
    }))

    return (
        <TextSelectDropdown
            options={options}
            onChange={onChangeHandler}
            value={options.find(option => option.value === currentBuildabilityType)}
            placeholder=''
            isClearable={false}
            isSearchable={false}
        />
    )
}

export default BuildabilitySwitcherButton
