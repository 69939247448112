import React, { useState } from 'react'
import RecommendationRow from '../recommendation-row/RecommendationRow'
import { ModelRecommendationsData } from '../../entities/ModelRecommendationsData'
import Equipment from '../../entities/Equipment'
import style from './recommendationsComponent.css'
import GridIcon from '../../assets/media/icons/grid.svg'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    recommendationsData: ModelRecommendationsData
    selectEquipment: Function
    openInfoModal: Function
}

const RecommendationsComponent: React.FC<Props> = ({ recommendationsData, selectEquipment, openInfoModal }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    const { t } = useTranslation()

    const toggleExpand = () => {
        setIsExpanded(!isExpanded)
    }

    if (!recommendationsData || !recommendationsData.recommendations || recommendationsData.recommendations.length === 0) {
        return null
    }

    const displayedRecommendations = recommendationsData.recommendations

    return (
        <div className={style.container}>
            <div className={style.header} onClick={toggleExpand}>
                <div className={style.headerTitle}>
                    <GridIcon/>
                    <h3>{t('recommendations.title')}</h3>
                </div>
                <span className={style.count}>{recommendationsData.recommendations.length}</span>
            </div>
            <div className={`${style.recommendationsList} ${isExpanded ? style.expanded : ''}`}>
                {displayedRecommendations.map((equipment: Equipment) => (
                    <RecommendationRow
                        key={equipment.id}
                        equipment={equipment}
                        selectEquipment={selectEquipment}
                        openInfoModal={openInfoModal}
                    />
                ))}
            </div>
        </div>
    )
}

export default RecommendationsComponent
