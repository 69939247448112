import React, { FunctionComponent } from 'react'
import Media from '../../entities/Media'
import MediaImg from '../media-img'
import style from './carlineImage.css'

export type Props = {
    media: Media[]
    alt: string
    className?: string
    width: number
    height: number
    backgroundColor?: string
    backgroundImageType: string
}

const CarlineImage: FunctionComponent<Props> = (props) => {
    const {
        media,
        width,
        height,
        className,
        alt,
        backgroundColor,
        backgroundImageType,
    } = props

    return (
        <MediaImg
            media={media}
            alt={alt}
            className={`${className} ${style.carlineImage}`}
            width={width}
            height={height}
            backgroundColor={backgroundColor}
            backgroundImageType={backgroundImageType}
        />
    )
}

export default CarlineImage
