import { connect, MapStateToProps } from 'react-redux'
import { Props } from './PrStringInvocationModal'
import prStringStatusDataSelector from '../../redux/selectors/prStringStatusDataSelector'
import prStringRequestIsPendingSelector from '../../redux/selectors/pending-request/prStringRequestIsPendingSelector'
import { loadConfigurationByPrString } from '../../redux/actions/app/prString.actions'

type StateProps = Pick<Props, 'prStringStatus' | 'isLoading'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    prStringStatus: prStringStatusDataSelector(state),
    isLoading: prStringRequestIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'loadConfigurationByPrString'>

const mapDispatchToProps: DispatchProps = {
    loadConfigurationByPrString: loadConfigurationByPrString,
}

export default connect(mapStateToProps, mapDispatchToProps)
