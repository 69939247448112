import { connect, MapStateToProps } from 'react-redux'
import navLinksSelector from '../../redux/selectors/navLinksSelector'
import { Props } from './Navigation'
import abortUrlSelector from '../../redux/selectors/url/abortUrlSelector'
import orderButtonsAreActiveSelector from '../../redux/selectors/feature-scope/orderButtonsAreActiveSelector'
import isComparisonButtonVisibleSelector from '../../redux/selectors/feature-scope/isComparisonButtonVisibleSelector'
import isLastConfigurationsButtonVisibleSelector from '../../redux/selectors/garage/isLastConfigurationsButtonVisibleSelector'
import isUserGarageButtonVisibleSelector
    from '../../redux/selectors/garage/isUserGarageButtonVisibleSelector'
import isTrampolineEnabledSelector
    from '../../redux/selectors/feature-scope/isTrampolineEnabledSelector'

type StateProps = Pick<
    Props,
    'navLinks'
    | 'abortUrl'
    | 'orderButtonsAreActive'
    | 'isComparisonButtonVisible'
    | 'isLastConfigurationsButtonVisible'
    | 'isUserGarageButtonVisible'
    | 'isTrampolineButtonEnabled'
>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    navLinks: navLinksSelector(state),
    orderButtonsAreActive: orderButtonsAreActiveSelector(state),
    abortUrl: abortUrlSelector(state),
    isComparisonButtonVisible: isComparisonButtonVisibleSelector(state),
    isLastConfigurationsButtonVisible: isLastConfigurationsButtonVisibleSelector(state),
    isUserGarageButtonVisible: isUserGarageButtonVisibleSelector(state),
    isTrampolineButtonEnabled: isTrampolineEnabledSelector(state),
})

export default connect(mapStateToProps)
