import { createSelector } from 'reselect'
import { ElementType } from 'react'
import NavLink, { NAV_LINK_STATUS_NONE } from '../../entities/NavLink'
import selectedCarlineIdSelector from './carlines/selectedCarlineIdSelector'
import configuredModelIdSelector from './models/configuredModelIdSelector'
import routerSelector from './router/routerSelector'
import {
    ROUTE_CARLINES,
    ROUTE_CHECKOUT,
    ROUTE_MODELS,
    ROUTE_OVERVIEW,
    ROUTE_TECHNICAL_DATA,
} from '../../constants/routes'
import CarlineGroup from '../../assets/media/icons/carline-group.svg'
import Cart from '../../assets/media/icons/cart.svg'
import EngineIcon from '../../assets/media/icons/engine.svg'
import OverviewIcon from '../../assets/media/icons/overview.svg'
import TechIcon from '../../assets/media/icons/tech.svg'
import ListIcon from '../../assets/media/icons/list.svg'
import ColorsIcon from '../../assets/media/icons/colors-small.svg'
import EquipmentIcon from '../../assets/media/icons/extra-equipment-small.svg'
import AudiExclusiveIcon from '../../assets/media/icons/audi-exclusive.svg'

import equipmentGroupsSelector from './equipments/equipmentGroupsSelector'
import urlifyGroupName from '../../helpers/urlifyGroupName'
import {
    EQUIPMENT_GROUP_STATUS_NONE,
    EQUIPMENT_GROUP_STATUS_OK,
} from '../../entities/EquipmentGroup'
import checkoutIsEnabledSelector from './checkoutIsEnabledSelector'
import modelsSelector from './models/modelsSelector'
import orderButtonsAreActiveSelector from './feature-scope/orderButtonsAreActiveSelector'
import orderUrlSelector from './url/orderUrlSelector'
import isTrampolineEnabledSelector from './feature-scope/isTrampolineEnabledSelector'
import configurationIsBuildableSelector from './configuration/configurationIsBuildableSelector'

const iconMap = {
    '01\\860': ColorsIcon,
    '01\\710': ColorsIcon,
    '01\\810': EquipmentIcon,
    '01\\820': EquipmentIcon,
    '01\\830': EquipmentIcon,
    '01\\840': EquipmentIcon,
    '01\\890': EquipmentIcon,
    '97\\900': EquipmentIcon,
    '97\\901': EquipmentIcon,
    860: ColorsIcon,
    710: ColorsIcon,
    810: EquipmentIcon,
    820: EquipmentIcon,
    830: EquipmentIcon,
    840: EquipmentIcon,
    890: EquipmentIcon,
    900: EquipmentIcon,
    901: EquipmentIcon,
    'audiExclusive': AudiExclusiveIcon,
}

const getEquipmentIcon = (id: string): ElementType => iconMap[id] || ListIcon

const navLinksSelector = createSelector(
    routerSelector,
    selectedCarlineIdSelector,
    configuredModelIdSelector,
    modelsSelector,
    equipmentGroupsSelector,
    checkoutIsEnabledSelector,
    orderButtonsAreActiveSelector,
    orderUrlSelector,
    isTrampolineEnabledSelector,
    configurationIsBuildableSelector,
    (
        router,
        carlineId,
        modelId,
        models,
        equipmentGroups,
        checkoutIsEnabled,
        orderButtonsAreActive,
        orderUrl,
        trampolineIsEnabled,
        configurationIsBuildable,
    ): NavLink[] => {
        const equipmentNavPoints = equipmentGroups.map((equipmentGroup) => {
            const equipmentGroupLink = `${urlifyGroupName(equipmentGroup.id)}`

            return {
                id: equipmentGroup.id,
                url: equipmentGroupLink,
                navLabel: `navigation.${equipmentGroup.id}`,
                title: `navigation.${equipmentGroup.id}`,
                iconComponent: getEquipmentIcon(equipmentGroup.id),
                isEnabled: modelId !== '',
                isActive: router.location.pathname === equipmentGroupLink,
                status: equipmentGroup.status,
            }
        })

        const navLinks: NavLink[] = [
            {
                id: 'carline-selection',
                url: ROUTE_CARLINES,
                navLabel: 'navigation.carlineSelection',
                title: 'navigation.carlineSelection',
                iconComponent: CarlineGroup,
                isEnabled: true,
                isActive: router.location.pathname === ROUTE_CARLINES,
                status: (carlineId !== '') ? EQUIPMENT_GROUP_STATUS_OK : EQUIPMENT_GROUP_STATUS_NONE,
            },
            {
                id: 'model-selection',
                url: ROUTE_MODELS,
                navLabel: 'navigation.modelSelection',
                title: 'navigation.modelSelection',
                iconComponent: EngineIcon,
                isEnabled: carlineId !== '' && models.length > 0,
                isActive: router.location.pathname === ROUTE_MODELS,
                status: (modelId !== '') ? EQUIPMENT_GROUP_STATUS_OK : EQUIPMENT_GROUP_STATUS_NONE,
            },
            ...equipmentNavPoints,
            {
                id: 'technical-data',
                url: ROUTE_TECHNICAL_DATA,
                navLabel: 'navigation.technicalData',
                iconComponent: TechIcon,
                title: 'navigation.technicalData',
                isEnabled: modelId !== '',
                isActive: router.location.pathname === ROUTE_TECHNICAL_DATA,
                status: NAV_LINK_STATUS_NONE,
            },
            {
                id: 'overview',
                url: ROUTE_OVERVIEW,
                navLabel: 'navigation.overview',
                iconComponent: OverviewIcon,
                title: 'cartNavigation.overview',
                isEnabled: modelId !== '',
                isActive: router.location.pathname === ROUTE_OVERVIEW,
                status: NAV_LINK_STATUS_NONE,
            },
        ]

        if ((orderButtonsAreActive && orderUrl) || trampolineIsEnabled) {
            navLinks.push({
                id: 'checkout',
                url: ROUTE_CHECKOUT,
                navLabel: 'navigation.goToCheckout',
                iconComponent: Cart,
                title: 'navigation.goToCheckout',
                isEnabled: checkoutIsEnabled || (trampolineIsEnabled && configurationIsBuildable),
                isActive: router.location.pathname === '/checkout',
                status: NAV_LINK_STATUS_NONE,
            })
        }

        return navLinks
    },
)

export default navLinksSelector
