import React, { FunctionComponent } from 'react'
import { stringifyUrl } from 'query-string'
import Media from '../../entities/Media'
import isEmpty from 'lodash/isEmpty'

export type Props = {
    media: Media[]
    alt: string
    className?: string
    width?: number
    height?: number
    backgroundColor?: string
    backgroundImageType?: string
}

const MediaImg: FunctionComponent<Props> = (props) => {
    const {
        media,
        alt,
        className,
        width,
        height,
        backgroundColor,
        backgroundImageType,
    } = props

    const images = media.filter((mediaItem) => mediaItem.type === 'image')
    const imgMedia = images.find((mediaItem) => mediaItem.backgroundType === backgroundImageType)
        || images.find((mediaItem) => mediaItem.backgroundType === 'DEFAULT')
        || images[0]

    if (imgMedia === undefined || !imgMedia.path || isEmpty(imgMedia.path)) {
        return null
    }

    const query = {
        downsize: width !== undefined ? `${width}px:*` : null,
        'background-color': backgroundColor !== undefined ? backgroundColor : null,
    }

    const backgroundStyle = backgroundColor !== undefined ? { background: `#${backgroundColor}` } : {}

    const src = stringifyUrl({
        url: imgMedia.path,
        query,
    }, {
        skipNull: true,
    })

    return (
        <img
            src={src}
            alt={alt}
            style={backgroundStyle}
            className={className}
            width={width}
            height={height}
        />
    )
}

export default MediaImg
