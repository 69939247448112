import { connect, MapStateToProps } from 'react-redux'
import { Props } from './BackgroundImageSwitcher'
import backgroundImageTypeSelector from '../../redux/selectors/backgroundImageTypeSelector'
import {
    setBackgroundImageTypeState,
} from '../../redux/actions/app/backgroundImageSwitcher.actions'
import renderImagesOrderingSelector
    from '../../redux/selectors/render-image/renderImagesOrderingSelector'
import { setActiveRenderImgIdxState } from '../../redux/actions/app/renderImages.actions'
import renderImagesSelector from '../../redux/selectors/renderImagesSelector'

type StateProps = Pick<Props, 'backgroundImageType' | 'renderImageOrdering' | 'renderImages'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state) => ({
    backgroundImageType: backgroundImageTypeSelector(state),
    renderImageOrdering: renderImagesOrderingSelector(state),
    renderImages: renderImagesSelector(state),
})

type DispatchProps = Pick<Props, 'setBackgroundImageType' | 'setActiveRenderImgIdx'>

const mapDispatchProps: DispatchProps = {
    setBackgroundImageType: setBackgroundImageTypeState,
    setActiveRenderImgIdx: setActiveRenderImgIdxState,
}

export default connect(mapStateToProps, mapDispatchProps)
