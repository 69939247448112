import { createReducer } from 'typesafe-actions'
import {
    resetVehicleCodeInState,
    setInitParametersToState,
} from '../actions/app/initParameters.actions'

export type InitParametersState = {
    contextId?: string
    dealerId?: string
    salesmanId?: string
    carstoreUserId?: string
    returnUrl?: string
    features?: string
    vehicleCode?: string
    token?: string
    entryUrl?: string
}

export const defaultState: InitParametersState = {}

const initParametersReducer = createReducer(defaultState)
    .handleAction(setInitParametersToState, (state, action) => action.payload)
    .handleAction(resetVehicleCodeInState, (state) => ({
        ...state,
        vehicleCode: defaultState.vehicleCode,
    }))

export default initParametersReducer
