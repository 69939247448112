const normalizeString = (value?: string): string | undefined => {
    if (!value) {
        return value
    }

    return value.replace(/[\n\t]/g, '').trim().replace(/\\u[\dA-F]{4}/gi, (match) => {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    })
}

export const normalizeUrl = (url: string): string => {
    if (url && url.startsWith('http://')) {
        return url.replace('http://', 'https://')
    }
    return url
}

export default normalizeString
