import { connect, MapStateToProps } from 'react-redux'
import filteredCarlineGroupsSelector from '../../redux/selectors/carlines/filteredCarlineGroupsSelector'
import carlineSelectionIsLoadingSelector from '../../redux/selectors/is-loading/carlineSelectionIsLoadingSelector'
import { Props } from './CarlineSelection'
import carlineGroupsDisplayModeSelector from '../../redux/selectors/carlines/carlineGroupsDisplayModeSelector'
import { setDisplayMode } from '../../redux/actions/app/carlineSelection.actions'
import isSwitchBuildabilityEnabledSelector from '../../redux/selectors/feature-scope/isSwitchBuildabilityEnabledSelector'
import isBackgroundImageSwitcherEnabledSelector from '../../redux/selectors/isBackgroundImageSwitcherEnabledSelector'
import carlineFiltersRequestIsPendingSelector from '../../redux/selectors/pending-request/carlineFiltersRequestIsPendingSelector'
import backgroundImageTypeSelector from '../../redux/selectors/backgroundImageTypeSelector'

type StateProps = Pick<
    Props,
    | 'carlineGroups'
    | 'displayMode'
    | 'isLoading'
    | 'carlineFiltersAreLoading'
    | 'displayBuildabilitySwitcher'
    | 'displayBackgroundImageSwitcher'
    | 'backgroundImageType'
>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    carlineGroups: filteredCarlineGroupsSelector(state),
    displayMode: carlineGroupsDisplayModeSelector(state),
    isLoading: carlineSelectionIsLoadingSelector(state),
    carlineFiltersAreLoading: carlineFiltersRequestIsPendingSelector(state),
    displayBuildabilitySwitcher: isSwitchBuildabilityEnabledSelector(state),
    displayBackgroundImageSwitcher: isBackgroundImageSwitcherEnabledSelector(state),
    backgroundImageType: backgroundImageTypeSelector(state),
})

type DispatchProps = Pick<Props, 'setDisplayMode'>

const mapDispatchToProps: DispatchProps = {
    setDisplayMode,
}

export default connect(mapStateToProps, mapDispatchToProps)
