import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { setActiveRenderImgIdx, setActiveRenderImgIdxState } from '../../actions/app/renderImages.actions'
import { fetchVisualizationAsync } from '../../actions/app/visualization.actions'
import { findViewIndex } from '../../selectors/render-image/helper/filterRenderImages'
import backgroundImageTypeSelector from '../../selectors/backgroundImageTypeSelector'

const renderImagesMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { getState, dispatch } = store

    if (isActionOf(setActiveRenderImgIdx, action)) {
        dispatch(setActiveRenderImgIdxState(action.payload))
    }

    if (isActionOf(fetchVisualizationAsync.success, action)) {
        const { selectedView, renderImages } = action.payload

        const backgroundImageType = backgroundImageTypeSelector(getState())

        const selectedViewIdx = findViewIndex(renderImages, backgroundImageType, selectedView)
        const idx = (selectedViewIdx === -1) ? 0 : selectedViewIdx

        dispatch(setActiveRenderImgIdxState(idx))
    }
}

export default renderImagesMiddleware
