import { createSelector } from 'reselect'
import renderImagesSelector from './renderImagesSelector'
import backgroundImageTypeSelector from './backgroundImageTypeSelector'
import RenderImage from '../../entities/RenderImage'
import renderImagesOrderingSelector from './render-image/renderImagesOrderingSelector'

const filteredRenderImagesSelector = createSelector(
    [renderImagesSelector, backgroundImageTypeSelector, renderImagesOrderingSelector],
    (renderImages, backgroundImageType, renderImagesOrder): RenderImage[] => {
        // return unfiltered images if no ordering is provided
        if (!renderImagesOrder || renderImagesOrder.length === 0) {
            return renderImages
        }

        // try filtering by backgroundImageType
        let filteredImages = renderImages.map(renderImage => ({
            ...renderImage,
            renderDetails: renderImage.renderDetails.filter(
                detail => detail.backgroundType === backgroundImageType,
            ),
        })).filter(renderImage => renderImage.renderDetails.length > 0)

        // if no matches then use renderImagesOrder as fallback
        if (filteredImages.length === 0) {
            const fallbackBackgroundType = renderImagesOrder.find(type =>
                renderImages.some(renderImage =>
                    renderImage.renderDetails.some(detail => detail.backgroundType === type),
                ),
            )

            if (fallbackBackgroundType) {
                filteredImages = renderImages.map(renderImage => ({
                    ...renderImage,
                    renderDetails: renderImage.renderDetails.filter(
                        detail => detail.backgroundType === fallbackBackgroundType,
                    ),
                })).filter(renderImage => renderImage.renderDetails.length > 0)
            }
        }

        return filteredImages
    },
)

export default filteredRenderImagesSelector
