import { connect, MapStateToProps } from 'react-redux'
import filteredRenderImagesSelector from '../../redux/selectors/filteredRenderImagesSelector'
import activeRenderImgIdxSelector from '../../redux/selectors/activeRenderImgIdxSelector'
import visualizationRequestIsPendingSelector from '../../redux/selectors/pending-request/visualizationRequestIsPendingSelector'
import { setActiveRenderImgIdx } from '../../redux/actions/app/renderImages.actions'
import { Props } from './RenderImagesPreview'
import renderImagesPreviewIsHiddenSelector from '../../redux/selectors/feature-scope/renderImagesPreviewIsHiddenSelector'

type StateProps = Pick<
    Props,
    | 'renderImages'
    | 'activeRenderImgIdx'
    | 'isLoading'
    | 'isHidden'
>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    renderImages: filteredRenderImagesSelector(state),
    activeRenderImgIdx: activeRenderImgIdxSelector(state),
    isLoading: visualizationRequestIsPendingSelector(state),
    isHidden: renderImagesPreviewIsHiddenSelector(state),
})

type DispatchProps = Pick<Props, 'setActiveRenderImgIdx'>

const mapDispatchToProps: DispatchProps = {
    setActiveRenderImgIdx,
}

export default connect(mapStateToProps, mapDispatchToProps)
