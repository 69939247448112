import { connect, MapStateToProps } from 'react-redux'
import { Props } from './Checkout'
import checkoutIsEnabledSelector from '../../redux/selectors/checkoutIsEnabledSelector'
import { startCheckout } from '../../redux/actions/app/checkout.actions'
import isTrampolineEnabledSelector
    from '../../redux/selectors/feature-scope/isTrampolineEnabledSelector'
import vehicleCodeRequestIsPendingSelector
    from '../../redux/selectors/pending-request/vehicleCodeRequestIsPendingSelector'

type StateProps = Pick<Props, 'checkoutIsEnabled' | 'trampolineIsEnabled' | 'vehicleCodeRequestPending'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    checkoutIsEnabled: checkoutIsEnabledSelector(state),
    trampolineIsEnabled: isTrampolineEnabledSelector(state),
    vehicleCodeRequestPending: vehicleCodeRequestIsPendingSelector(state),
})

type DispatchProps = Pick<Props, 'startCheckout'>

const mapDispatchToProps: DispatchProps = {
    startCheckout,
}

export default connect(mapStateToProps, mapDispatchToProps)
