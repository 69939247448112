import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import useOnMount from '../../hooks/useOnMount'
import style from './checkout.css'

export type Props = {
    checkoutIsEnabled: boolean
    startCheckout: Function
    trampolineIsEnabled: boolean
    vehicleCodeRequestPending: boolean
}

const Checkout: FunctionComponent<Props> = (props) => {
    const { checkoutIsEnabled, startCheckout, trampolineIsEnabled, vehicleCodeRequestPending } = props
    const { t } = useTranslation()

    useOnMount(() => {
        if (checkoutIsEnabled || trampolineIsEnabled) {
            startCheckout()
        }
    })

    return (
        <div className={style.container} data-cy="carline-group-list-gridview">
            <section className={style.headerContainer}>
                {trampolineIsEnabled
                    ? (<h3> {vehicleCodeRequestPending ? t('loading') : t('checkout.trampoline')}</h3>)
                    : (
                        <>
                            <h1>
                                {t('checkout.heading')}
                            </h1>

                            <p>
                                {t('checkout.infoText')}
                            </p>
                        </>
                    )}
            </section>
        </div>
    )
}

export default Checkout
