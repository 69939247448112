import { Middleware } from 'redux'
import { getType, isActionOf } from 'typesafe-actions'
import initRouterParamsSelector, { Params } from '../../selectors/initRouterParamsSelector'
import initParametersSelector from '../../selectors/initParametersSelector'
import { setInitParametersToState } from '../../actions/app/initParameters.actions'
import {
    fetchEntryData,
    fetchEntryDataAsync,
    resetEntryDataState,
} from '../../actions/app/entryData.actions'
import currentPathnameSelector from '../../selectors/router/currentPathnameSelector'
import { ROUTE_CHECK } from '../../../constants/routes'
import { resetCartState } from '../../actions/app/cart.actions'
import { resetCarlineSelectionState } from '../../actions/app/carlineSelection.actions'
import { resetModelDataState } from '../../actions/app/models.actions'
import { resetConfigurationDataState } from '../../actions/app/configuration.actions'
import { resetEquipmentGroupsState } from '../../actions/app/equipmentGroups.actions'
import { resetFilters } from '../../actions/app/filter.actions'
import { init } from '../../actions/core/init.actions'
import { createNotification, resetNotificationsState } from '../../actions/app/notification.actions'
import i18n from '../../../i18n/i18n'
import { vehicleCodeChanged } from '../../actions/app/vehicleCode.actions'
import failedRequestsSelector from '../../selectors/failedRequestsSelector'
import { applyBrand } from '../../actions/app/brand.actions'
import { resetModelsWithRecommendationsData, resetRecommendationsForModelData } from '../../actions/app/recommendation.actions'

const mapRouterParamsToInitParams = (routerParams: Params): Record<string, string> => ({
    contextId: routerParams.context || routerParams.mandant,
    dealerId: routerParams.dealer_number,
    salesmanId: routerParams.salesman_number,
    carstoreUserId: routerParams.carstore_user_id,
    returnUrl: routerParams.return_url,
    features: routerParams.features,
    vehicleCode: routerParams.vehicle_code,
    token: routerParams.token,
    entryUrl: window.location.href,
})

const initMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)

    if (isActionOf(init, action)) {
        const { getState, dispatch } = store
        const failedRequests = failedRequestsSelector(getState())

        failedRequests
            .filter(failedRequest => failedRequest.type !== getType(fetchEntryDataAsync.request) && failedRequest.type !== getType(fetchEntryData))
            .forEach((failedRequest) => {
                dispatch(failedRequest)
            })

        dispatch(applyBrand())
        dispatch(resetNotificationsState())

        const initRouterParams = initRouterParamsSelector(getState())
        const initStateParams = initParametersSelector(getState())
        const currentPathname = currentPathnameSelector(getState())
        const routerContextParamValue = initRouterParams.context || initRouterParams.mandant

        if (currentPathname === ROUTE_CHECK) {
            return
        }

        if (!routerContextParamValue && !initStateParams.contextId) {
            // eslint-disable-next-line no-alert
            alert('No context or mandant parameter provided')
            return
        }

        if (routerContextParamValue && !initStateParams.contextId) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(setInitParametersToState(initParameters))
            dispatch(fetchEntryData())

            return
        }

        if (initStateParams.vehicleCode !== initRouterParams.vehicle_code) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(setInitParametersToState(initParameters))
            dispatch(vehicleCodeChanged())
        }

        if (!routerContextParamValue && initStateParams.contextId) {
            return
        }

        if (initStateParams.contextId !== routerContextParamValue
            || initStateParams.token !== initRouterParams.token) {
            const initParameters = mapRouterParamsToInitParams(initRouterParams)

            dispatch(resetEntryDataState())
            dispatch(resetCartState())
            dispatch(resetCarlineSelectionState())
            dispatch(resetModelDataState())
            dispatch(resetConfigurationDataState())
            dispatch(resetEquipmentGroupsState())
            dispatch(resetFilters())
            dispatch(resetModelsWithRecommendationsData())
            dispatch(resetRecommendationsForModelData())
            dispatch(setInitParametersToState(initParameters))

            dispatch(fetchEntryData())

            dispatch(createNotification({
                type: 'info',
                message: i18n.t('notifications.context-changed'),
            }))
        }
    }
}

export default initMiddleware
