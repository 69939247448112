import { createSelector } from 'reselect'
import NavLink from '../../../entities/NavLink'
import navLinksSelector from '../navLinksSelector'
import { EQUIPMENT_GROUP_STATUS_INCOMPLETE } from '../../../entities/EquipmentGroup'
import currentPathnameSelector from '../router/currentPathnameSelector'
import checkoutIsEnabledSelector from '../checkoutIsEnabledSelector'
import { ROUTE_OVERVIEW } from '../../../constants/routes'
import isTrampolineEnabledSelector from '../feature-scope/isTrampolineEnabledSelector'

const cartNavLinkSelector = createSelector(
    navLinksSelector,
    currentPathnameSelector,
    checkoutIsEnabledSelector,
    isTrampolineEnabledSelector,
    (navLinks, currentPathName, checkoutIsEnabled, trampolineIsEnabled): NavLink => {
        if (currentPathName === ROUTE_OVERVIEW) {
            const incompleteEquipmentNavLink = navLinks.find((navLink) => (
                navLink.status === EQUIPMENT_GROUP_STATUS_INCOMPLETE
            ))

            if (incompleteEquipmentNavLink !== undefined) {
                return incompleteEquipmentNavLink
            }

            if (!checkoutIsEnabled && !trampolineIsEnabled) {
                const overviewNavLink = navLinks.find((navLink) => navLink.url === ROUTE_OVERVIEW)

                return overviewNavLink
            }
        }

        const activeNavLinkIdx = navLinks.findIndex((navLink) => navLink.url === currentPathName)
        const nextNavLinkIdx = activeNavLinkIdx + 1

        return navLinks[nextNavLinkIdx]
    },
)

export default cartNavLinkSelector
