import React, { useState, useEffect } from 'react'
import Modal, { ModalClosingButton } from '../modal'
import CautionIcon from '../../assets/media/icons/caution.svg'
import ListIcon from '../../assets/media/icons/list.svg'
import style from './prStringInvocationModal.css'
import PrStringStatus from '../../entities/PrStringStatus'
import { Radio } from '../form'
import useTranslation from '../../hooks/useTranslation'
import PrString from '../../entities/PrString'

export type Props = {
    prStringStatus: PrStringStatus
    isLoading: boolean
    loadConfigurationByPrString: (prString: PrString) => void
}

const PrStringInvocationModal: React.FC<Props> = (props) => {
    const { prStringStatus, loadConfigurationByPrString } = props
    const [openModalState, setOpenModalState] = useState(false)
    const [invocationPrString, setInvocationPrString] = useState<string | null>(null)
    const PR_STRING = 'prstring'
    const PR_STRING_ALT = 'prString'
    const { t } = useTranslation()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const initialPrString = urlParams.get(PR_STRING) || urlParams.get(PR_STRING_ALT)
        if (initialPrString) {
            setInvocationPrString(initialPrString)
        }
        if (prStringStatus && prStringStatus.alternatives.length > 0) {
            setOpenModalState(true)
        }
    }, [prStringStatus])

    const closeHandler = (): void => {
        setOpenModalState(false)
    }

    const handleOptionChange = (prString: string): void => {
        setInvocationPrString(prString)
        loadConfigurationByPrString(encodeURI(prString))
        setOpenModalState(false)
    }

    return (
        <Modal isVisible={openModalState} onClose={closeHandler}>
            <ModalClosingButton onClick={closeHandler} />
            <section className={style.modalContainer}>
                <h2 className={style.modalTitle}>{t('prStringInvocationModal.title')}</h2>
                <div className={style.modalMessage}>
                    <CautionIcon className={style.icon} />
                    <span>{t('prStringInvocationModal.message')}</span>
                </div>
                <div className={style.section}>
                    <h3 className={style.sectionTitle}>{t('prStringInvocationModal.sectionTitle')}</h3>
                    <div className={style.invocation}>
                        {invocationPrString || 'No invocation selected'}
                    </div>
                </div>
                <div className={style.section}>
                    <div className={style.matchingModelsSection}>
                        <ListIcon className={style.icon} />
                        <h3>{t('prStringInvocationModal.matchingModels')}</h3>
                    </div>
                    <div className={style.options}>
                        {prStringStatus?.alternatives.map((model, index) => (
                            <button key={index} className={style.option} onClick={() => handleOptionChange(model)}>
                                <Radio className={style.radio} checked={invocationPrString === model} />
                                <span>{model}</span>
                            </button>
                        ))}
                    </div>
                </div>
            </section>
        </Modal>
    )
}

export default PrStringInvocationModal
