import { connect, MapStateToProps } from 'react-redux'
import { Props } from './CarlineGridGroup'
import activeCarlineGroupIdSelector from '../../redux/selectors/carlines/activeCarlineGroupIdSelector'
import { setActiveCarlineGroupId } from '../../redux/actions/app/carlineSelection.actions'
import backgroundImageTypeSelector from '../../redux/selectors/backgroundImageTypeSelector'

type StateProps = Pick<Props, 'activeGroupId' | 'backgroundImageType'>

const mapStateToProps: MapStateToProps<StateProps, {}> = (state): StateProps => ({
    activeGroupId: activeCarlineGroupIdSelector(state),
    backgroundImageType: backgroundImageTypeSelector(state),
})

type DispatchProps = Pick<Props, 'setActiveCarlineGroupId'>

const mapDispatchToProps: DispatchProps = {
    setActiveCarlineGroupId,
}

export default connect(mapStateToProps, mapDispatchToProps)
