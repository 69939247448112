import { createAction, createAsyncAction } from 'typesafe-actions'
import PrStringStatus from '../../../entities/PrStringStatus'
import PrString from '../../../entities/PrString'
import { PrStringStatusDataState } from '../../reducers/prStringStatusDataReducer'

export const loadConfigurationByPrString = createAction('CMD / PrString / get configuration')<PrString>()

export const loadConfigurationByPrStringAsync = createAsyncAction(
    'EVT / PrString / get configuration request',
    'EVT / PrString / get configuration success',
    'EVT / PrString / get configuration failure',
)<PrString, PrStringStatus, Error>()

export const setPrStringStatusState = createAction('DOC / PrStringStatusData / set state')<PrStringStatusDataState>()
export const resetPrStringStatusState = createAction('DOC / PrStringStatusData / reset state')()
