import React, { FunctionComponent, useState } from 'react'
import style from './expandText.css'
import useTranslation from '../../hooks/useTranslation'

export type Props = {
    title?: string
    text: string
    charLimit: number
}

const ExpandableText: FunctionComponent<Props> = (props) => {
    const {title, text, charLimit} = props

    const [isExpanded, setIsExpanded] = useState(false)
    const { t } = useTranslation()
    const isTruncated = text.length > charLimit

    const toggleExpand = () => setIsExpanded(prev => !prev)

    return (
        <section className={style.container}>
            {title ? <h2>{title}</h2> : ''}
            <p className={style.textContainer}>
                {isExpanded ? text : `${text.substring(0, charLimit)}${isTruncated ? '...' : ''}`}
            </p>
            {isTruncated && (
                <button onClick={toggleExpand} className={style.expandButton}>
                    {isExpanded ? t('expandableText.showLessButtonText') : t('expandableText.showMoreButtonText')}
                </button>
            )}
        </section>
    )
}

export default ExpandableText
