import React from 'react'
import Equipment from '../../entities/Equipment'
import style from './recommendationRow.css'
import { Markup } from 'interweave'
import InfoButton from '../info-button'
import InfoI from '../info-i'
import MediaImg from '../media-img'

type Props = {
    equipment: Equipment
    selectEquipment: Function
    openInfoModal: Function
}

const RecommendationRow: React.FC<Props> = ({ equipment, selectEquipment, openInfoModal }) => {
    const { id, name, media, price, selected, selectable, content, evaKey, validPeriod } = equipment
    const infoIsForced = media && media.find((mediaItem) => mediaItem.forced) !== undefined
    const hasInfo = (media && media.length > 0) || (content && content.length > 0)

    const changeHandler = (): void => {
        if (!selected) {
            selectEquipment(id)
        }
    }

    const openModal = (activeMedia: string | null = null): Function => () =>
        openInfoModal({
            title: name,
            media,
            activeMedia,
            content,
        })

    let infoButtonElement = null

    if (validPeriod || hasInfo) {
        infoButtonElement = (
            <InfoButton openModal={openModal()} dataCy={`info-button-${id}`}>
                <InfoI isForced={infoIsForced} />
            </InfoButton>
        )
    }

    const imgElement = media && <MediaImg alt={name} media={media} width={100} />
    const imgInfoButtonElement = imgElement !== null ? (
        <InfoButton openModal={openModal('image')} dataCy={`info-img-button-${id}`}>
            {imgElement}
        </InfoButton>
    ) : null

    return (
        <section className={style.row}>
            <div className={style.detailsWrapper}>
                <div className={style.textAndImage}>
                    <span className={style.name}>
                        {name}
                        <span className={style.displayId}>{evaKey}</span>
                    </span>
                    {media && media.length > 0 && <div className={style.media}>{imgInfoButtonElement}</div>}
                </div>
                <div className={style.footerWrapper}>
                    <div className={style.priceWrapper}>
                        <span className={style.price}>
                            <Markup content={price} />
                        </span>
                        {infoButtonElement}
                    </div>
                    <button
                        onClick={changeHandler}
                        disabled={!selectable}
                        className={style.addButton}
                        data-cy={`add-button-${id}`}
                    >
                        Add
                    </button>
                </div>
            </div>
        </section>
    )
}

export default RecommendationRow
