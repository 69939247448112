const region = process.env.COCOS_REGION || 'eu'
const insertRegion = (value: string): string => value.replace('{{REGION}}', region)

const getApiUrl = () => {
    const defaultApiUrl = insertRegion(process.env.API_URL ?? '')
    const currentOrigin = window.location.origin

    const DCC_APP_ENV_PATTERN = /dcc-app.*audi\.io/
    const LOCALHOST_PATTERN = /localhost/

    if (DCC_APP_ENV_PATTERN.test(currentOrigin) || LOCALHOST_PATTERN.test(currentOrigin)) {
        return defaultApiUrl
    }

    return `${currentOrigin}/dccbff`
}

export default Object.freeze({
    STAGE: process.env.SPACE,
    APP_VERSION: process.env.APP_VERSION ?? '',
    API_URL: getApiUrl(),
    TRANSLATION_SERVICE_URL: insertRegion(process.env.TRANSLATION_SERVICE_URL ?? ''),
    TRACKING_URL: process.env.TRACKING_URL ?? '',
    PDF_SERVICE_URL: process.env.PDF_SERVICE_URL ?? '',
    MIWI_FEATURE_LIBRARY_URL: process.env.MIWI_FEATURE_LIBRARY_URL ?? '',
    MIWI_FEATURE_APP_URL: insertRegion(process.env.MIWI_FEATURE_APP_URL ?? ''),
    MIWI_MOD_FEATURE_APP_URL: insertRegion(process.env.MIWI_MOD_FEATURE_APP_URL ?? ''),
    MIWI_FEATURE_BACKEND_SWITCHER: process.env.MIWI_FEATURE_BACKEND_SWITCHER ?? 'disabled',
    REACT_VERSION: process.env.REACT_VERSION,
    FEATURE_HUB_REACT_VERSION: process.env.FEATURE_HUB_REACT_VERSION,
    AUDI_EXCLUSIVE_WEB_COMPONENT_VERSION: process.env.AUDI_EXCLUSIVE_WEB_COMPONENT_VERSION ?? '',
})
