import { createReducer } from 'typesafe-actions'
import { resetPrStringStatusState, setPrStringStatusState } from '../actions/app/prString.actions'
import PrStringStatus from '../../entities/PrStringStatus'

export type PrStringStatusDataState = PrStringStatus

export const defaultState: PrStringStatusDataState = null

const prStringStatusDataReducer = createReducer(defaultState)
    .handleAction(setPrStringStatusState, (state, action) => action.payload)
    .handleAction(resetPrStringStatusState, (() => defaultState))

export default prStringStatusDataReducer
