import { Middleware } from 'redux'
import { isActionOf } from 'typesafe-actions'
import { checkoutGotVehicleCode, checkoutRedirect, startCheckout } from '../../actions/app/checkout.actions'
import orderUrlSelector from '../../selectors/url/orderUrlSelector'
import { createVehicleCode, setVehicleCodeState } from '../../actions/app/vehicleCode.actions'
import vehicleCodeSelector from '../../selectors/vehicleCodeSelector'
import isTrampolineEnabledSelector from '../../selectors/feature-scope/isTrampolineEnabledSelector'
import initParametersSelector from '../../selectors/initParametersSelector'

const checkoutMiddleware: Middleware = (store) => (next) => (action) => {
    next(action)
    const { dispatch, getState } = store

    if (isActionOf(startCheckout, action)) {
        dispatch(createVehicleCode(undefined, {
            onSuccess: checkoutGotVehicleCode,
        }))
    }

    if (isActionOf(checkoutGotVehicleCode, action)) {
        const { vehicleCode } = action.payload

        dispatch(setVehicleCodeState(vehicleCode))
        dispatch(checkoutRedirect())
    }

    if (isActionOf(checkoutRedirect, action)) {
        const state = getState()
        const orderUrl = orderUrlSelector(state)
        const vehicleCode = vehicleCodeSelector(state)
        const trampolineIsEnabled = isTrampolineEnabledSelector(state)

        if (!trampolineIsEnabled) {
            window.location.href = `${orderUrl}${vehicleCode}`
        } else {
            const { entryUrl } = initParametersSelector(state)
            const parsedUrl = new URL(entryUrl)
            const searchParams = new URLSearchParams(parsedUrl.search)

            const dealerNumber = searchParams.get('dealer_number') || ''
            const salesmanNumber = searchParams.get('salesman_number') || ''
            const context = searchParams.get('context')
            const mandant = searchParams.get('mandant')

            const baseUrl = parsedUrl.pathname === "/"
                ? parsedUrl.origin
                : `${parsedUrl.origin}${parsedUrl.pathname}`

            const updatedParams = new URLSearchParams()

            if (context) {
                updatedParams.set('context', context)
            } else if (mandant) {
                updatedParams.set('mandant', mandant)
            }

            updatedParams.set('audicode', vehicleCode)
            updatedParams.set('dealer_number', dealerNumber)
            updatedParams.set('salesman_number', salesmanNumber)
            updatedParams.set('configuration_process', 'finished')

            const trampolineUrl = `${baseUrl}${'?' + updatedParams.toString()}`

            window.history.replaceState({}, '', trampolineUrl)
        }
    }
}

export default checkoutMiddleware
