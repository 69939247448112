import React, { FunctionComponent } from 'react'
import TextSelectDropdown from '../text-select-dropdown/TextSelectDropdown'
import { OptionType } from '../../entities/OptionType'
import useTranslation from '../../hooks/useTranslation'
import RenderImage from '../../entities/RenderImage'
import { findViewIndex } from '../../redux/selectors/render-image/helper/filterRenderImages'

export type Props = {
    setBackgroundImageType: (backgroundType: string) => void
    setActiveRenderImgIdx: (activeIndex: number) => void
    backgroundImageType: string
    renderImageOrdering: string[]
    renderImages: RenderImage[]
}

const BackgroundImageSwitcher: FunctionComponent<Props> = (
    {
        backgroundImageType,
        setBackgroundImageType,
        renderImageOrdering,
        setActiveRenderImgIdx,
        renderImages,
    }) => {
    const { t } = useTranslation()
    const DEFAULT_VIEW = 'exterior_side-middle'

    const options = renderImageOrdering.map((renderImgOrder) => (
        {
            label: t(`backgroundImageSwitcher.label.${renderImgOrder}`),
            value: renderImgOrder,
        }
    ))

    const onChangeHandler = (selectedOption: OptionType) => {
        const selectedBackgroundType = selectedOption.value

        // for background switch, index view in shopping cart should be reset
        const defaultViewIndex = findViewIndex(renderImages, selectedBackgroundType, DEFAULT_VIEW)
        const idx = defaultViewIndex === -1 ? 0 : defaultViewIndex

        setBackgroundImageType(selectedBackgroundType)
        setActiveRenderImgIdx(idx)
    }

    return (
        <TextSelectDropdown
            options={options}
            onChange={onChangeHandler}
            value={options.find(option => option.value === backgroundImageType)}
            placeholder=''
            isClearable={false}
            isSearchable={false}
        />
    )

}

export default BackgroundImageSwitcher
