import React, { FunctionComponent } from 'react'
import useTranslation from '../../hooks/useTranslation'
import Carline from '../../entities/Carline'
import CancelIcon from '../../assets/media/icons/cancel.svg'
import SelectCarlineButton from '../select-carline-button'
import style from './carlineGridGroupCarlines.css'
import CarlineGroupPlaceholderImage from '../../assets/media/car-silhouette56.svg'
import CarlineImage from '../carline-image'

export type Props = {
    carlineGroupName: string
    carlines: Carline[]
    closeCarlineList: Function
    backgroundImageType: string
}

const CarlineGridGroupCarlines: FunctionComponent<Props> = (props) => {
    const { carlineGroupName, carlines, closeCarlineList, backgroundImageType } = props
    const { t } = useTranslation()

    const carlineElements = carlines.map((carline) => {
        const { id, media } = carline
        const imgElement = media?.length > 0 ? (
            <CarlineImage
                media={media}
                alt={carline.name}
                className={style.img}
                width={590}
                height={253}
                backgroundColor="e5e5e5"
                backgroundImageType={backgroundImageType}
            />
        ) : <CarlineGroupPlaceholderImage className={style.placeholder} />

        return (
            <li key={id}>
                <SelectCarlineButton id={id} additionalStyle="">
                    {imgElement}
                    <span className={style.title}>
                        {carline.name}
                        {' '}
                        {carline.mbvModelYear}
                    </span>
                </SelectCarlineButton>
            </li>
        )
    })

    const closeList = (): void => {
        closeCarlineList()
    }

    return (
        <div className={style.container}>
            <div className={style.header}>
                <h2>
                    {t('carlineSelection.subHeading')}
                    {' '}
                    {carlineGroupName}
                </h2>
                <button type="button" onClick={closeList} className={style.closeButton}>
                    <CancelIcon />
                </button>
            </div>
            <ul className={style.list}>
                {carlineElements}
            </ul>
        </div>
    )
}

export default CarlineGridGroupCarlines
