import { createSelector } from 'reselect'
import featureScopesSelector from './feature-scope/featureScopesSelector'
import renderImagesOrderingSelector from './render-image/renderImagesOrderingSelector'

const isBackgroundImageSwitcherEnabledSelector = createSelector(
    featureScopesSelector,
    renderImagesOrderingSelector,
    (featureScopes, renderImagesOrdering): boolean => featureScopes.includes('switchBackgroundImage')
        && renderImagesOrdering
        && renderImagesOrdering.length > 1,
)

export default isBackgroundImageSwitcherEnabledSelector
